define("discourse/plugins/discourse-data-explorer/discourse/components/explorer-schema", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _utils, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hideSchema}}
    <DButton
      @action={{this.expandSchema}}
      @icon="chevron-left"
      @class="no-text unhide"
    />
  {{else}}
    <div class="schema">
      <div class="schema-search inline-form full-width">
        <input
          type="text"
          {{on "input" (action "filterChanged" value="target.value")}}
        />
        <DButton
          @action={{this.collapseSchema}}
          @icon="chevron-right"
          @class="no-text"
        />
      </div>
  
      <div class="schema-container">
        <ConditionalLoadingSpinner @condition={{this.loading}}>
          <ul>
            {{#each this.filteredTables as |table|}}
              <ExplorerSchema::OneTable @table={{table}} />
            {{/each}}
          </ul>
        </ConditionalLoadingSpinner>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "Ylr1IHKl",
    "block": "[[[41,[30,0,[\"hideSchema\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@action\",\"@icon\",\"@class\"],[[30,0,[\"expandSchema\"]],\"chevron-left\",\"no-text unhide\"]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"schema\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"schema-search inline-form full-width\"],[12],[1,\"\\n      \"],[11,\"input\"],[24,4,\"text\"],[4,[38,2],[\"input\",[28,[37,3],[[30,0],\"filterChanged\"],[[\"value\"],[\"target.value\"]]]],null],[12],[13],[1,\"\\n      \"],[8,[39,1],null,[[\"@action\",\"@icon\",\"@class\"],[[30,0,[\"collapseSchema\"]],\"chevron-right\",\"no-text\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"schema-container\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"filteredTables\"]]],null]],null],null,[[[1,\"            \"],[8,[39,7],null,[[\"@table\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"table\"],false,[\"if\",\"d-button\",\"on\",\"action\",\"conditional-loading-spinner\",\"each\",\"-track-array\",\"explorer-schema/one-table\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/explorer-schema.hbs",
    "isStrictMode": false
  });
  let ExplorerSchema = _exports.default = (_dec = (0, _decorators.debounce)(500), (_class = class ExplorerSchema extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "filter", _descriptor, this);
      _initializerDefineProperty(this, "loading", _descriptor2, this);
      _initializerDefineProperty(this, "hideSchema", _descriptor3, this);
    }
    get transformedSchema() {
      const schema = this.args.schema;
      for (const key in schema) {
        if (!schema.hasOwnProperty(key)) {
          continue;
        }
        schema[key].forEach(col => {
          const notes_components = [];
          if (col.primary) {
            notes_components.push("primary key");
          }
          if (col.is_nullable) {
            notes_components.push("null");
          }
          if (col.column_default) {
            notes_components.push("default " + col.column_default);
          }
          if (col.fkey_info) {
            notes_components.push("fkey " + col.fkey_info);
          }
          if (col.denormal) {
            notes_components.push("denormal " + col.denormal);
          }
          const notes = notes_components.join(", ");
          if (notes) {
            col.notes = notes;
          }
          if (col.enum || col.column_desc) {
            col.havepopup = true;
          }
          col.havetypeinfo = !!(col.notes || col.enum || col.column_desc);
        });
      }
      return schema;
    }
    get filteredTables() {
      let tables = [];
      let filter = this.filter;
      try {
        if (!(0, _utils.isBlank)(this.filter)) {
          filter = new RegExp(this.filter);
        }
      } catch {
        filter = null;
      }
      const haveFilter = !!filter;
      for (const key in this.transformedSchema) {
        if (!this.transformedSchema.hasOwnProperty(key)) {
          continue;
        }
        if (!haveFilter) {
          tables.push({
            name: key,
            columns: this.transformedSchema[key],
            open: false
          });
          continue;
        }

        // Check the table name vs the filter
        if (filter.source === key || filter.source + "s" === key) {
          tables.unshift({
            name: key,
            columns: this.transformedSchema[key],
            open: haveFilter
          });
        } else if (filter.test(key)) {
          // whole table matches
          tables.push({
            name: key,
            columns: this.transformedSchema[key],
            open: haveFilter
          });
        } else {
          // filter the columns
          let filterCols = [];
          this.transformedSchema[key].forEach(col => {
            if (filter.source === col.column_name) {
              filterCols.unshift(col);
            } else if (filter.test(col.column_name)) {
              filterCols.push(col);
            }
          });
          if (!(0, _utils.isEmpty)(filterCols)) {
            tables.push({
              name: key,
              columns: filterCols,
              open: haveFilter
            });
          }
        }
      }
      return tables;
    }
    updateFilter(value) {
      this.filter = value;
      this.loading = false;
    }
    filterChanged(value) {
      this.loading = true;
      this.updateFilter(value);
    }
    collapseSchema() {
      this.hideSchema = true;
      this.args.updateHideSchema(true);
    }
    expandSchema() {
      this.hideSchema = false;
      this.args.updateHideSchema(false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hideSchema", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.hideSchema;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateFilter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filterChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "collapseSchema", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "collapseSchema"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expandSchema", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "expandSchema"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExplorerSchema);
});